import { getAuthToken } from "@/lib/use-auth";
import { dateComparator } from "@/lib/utils";
import { Environment, EnvironmentResponse, parseEnvironmentResponse } from "@/types/app/environment";
import { Evaluator, EvaluatorResponse } from "@/types/app/evaluator";
import { File, FileResponse, FileType, parseFileResponse } from "@/types/app/file";
import { VersionStatus } from "@/types/app/version";
import { Page } from "@/types/generic";
import { ToastVariant, hlToast, hlToastApiError } from "@components/library/Toast";
import { getFileTypeFromId } from "@components/library/utils/versions";
import { AxiosPromise } from "axios";
import dayjs, { Dayjs } from "dayjs";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import useSWR, { Arguments, SWRConfiguration, mutate, preload } from "swr";
import useSWRInfinite, { SWRInfiniteConfiguration } from "swr/infinite";
import { ApiService } from "./api.service";
import { FileOrDirectory } from "./directories.service";
import { parseTimestampsInResponse } from "./utils";

export interface FileEnvironmentVariable {
  name: string;
  value: string;
}

const fileFetcher = async (...args: Parameters<typeof ApiService.getWithToken>): Promise<File> => {
  const response: FileResponse = await ApiService.getWithToken(...args);
  return parseFileResponse(response);
};

const filesFetcher = async <F extends File = File, D extends { records: F[] } = { records: F[] }>(
  ...args: Parameters<typeof ApiService.getWithToken>
): Promise<D> => {
  const response: D & { records: Extract<FileResponse, { type: F["type"] }>[] } = await ApiService.getWithToken(
    ...args,
  );
  return {
    ...response,
    records: response.records.map(parseFileResponse),
  } satisfies D;
};

/**
 * Global mutate to update all Versions for a file.
 *
 * This avoids having to call useVersions (and its variations) for its mutate.
 */
export const refetchVersionsForFile = (fileId: string): void => {
  // Global mutate to update all files in case you've changed the name of one of them etc.
  // See https://swr.vercel.app/docs/mutation#mutate-multiple-items
  mutate(
    (key: Arguments) =>
      Array.isArray(key) && fileId && key[0].startsWith(`/v5/${getApiResourceFromFileId(fileId)}/${fileId}/versions`),
  );
};

export const useVersions = <F extends File, D extends { records: F[] }>(
  fileId: string | null | undefined,
  status: VersionStatus | null = "committed",
  evaluationAggregates: boolean = false,
  additionalUrlParams: Record<string, string> | undefined = undefined,
  swrOptions: SWRConfiguration<D> = {},
) => {
  const baseKey = fileId ? `/v5/${getApiResourceFromFileId(fileId)}/${fileId}/versions` : null;

  const urlParams = new URLSearchParams();
  if (status !== null) {
    urlParams.set("status", status);
  }
  if (evaluationAggregates) {
    urlParams.set("evaluator_aggregates", "true");
  }

  if (additionalUrlParams !== undefined) {
    for (const [key, value] of Object.entries(additionalUrlParams)) {
      urlParams.set(key, value);
    }
  }

  const fetcherKey = fileId ? `${baseKey}?${urlParams.toString()}` : null;

  const { data, error } = useSWR<D>(fileId ? [fetcherKey, getAuthToken()] : null, filesFetcher, swrOptions);

  const mutate = useCallback(() => {
    if (fileId) {
      refetchVersionsForFile(fileId);
    }
  }, [fileId]);

  return { versions: data?.records, error, loading: !data && !error, mutate };
};

/** Retrieves the version to load as a default in editor.
 *
 * Attempts to load the latest committed version.
 * If no committed versions exist, it will load the latest uncommitted version.
 */
export const useDefaultEditorVersion = <F extends File, D extends { records: F[] }>(
  fileId: string | null | undefined,
  additionalUrlParams: Record<string, string> | undefined = undefined,
  swrOptions: SWRConfiguration<D> = {},
) => {
  const [versionStatus, setVersionStatus] = useState<VersionStatus | null>("committed");
  const {
    versions,
    loading: loadingVersions,
    ...others
  } = useVersions<F, D>(fileId, versionStatus, false, additionalUrlParams, swrOptions);

  // Fall back to uncommitted if no committed versions exist.
  useEffect(() => {
    if (versions && versions.length === 0 && versionStatus === "committed") {
      setVersionStatus("uncommitted");
    }
  }, [versions, versionStatus]);

  const sortedVersions =
    versionStatus === "committed"
      ? versions?.toSorted((a, b) => dateComparator(a.committed_at, b.committed_at))
      : versions?.toSorted((a, b) => dateComparator(a.created_at, b.created_at));
  const latestVersion = sortedVersions ? sortedVersions[sortedVersions.length - 1] : undefined;

  // The "loading" state we want to expose should only become `false` if we've found a version,
  // or we've tried to load the uncommitted versions and still found none.
  const loading = versionStatus === "committed" ? !(versions && versions.length > 0) : loadingVersions;

  return { version: latestVersion, loading, ...others };
};

export interface GetFileProps {
  fileId: string;
  versionId?: string | undefined | null;
  environment?: string | undefined | null;
}

export const getFileUrl = (
  props: GetFileProps,
  additionalUrlParams: Record<string, string> | Array<[string, string]> | undefined = undefined,
) => {
  const { fileId, versionId, environment } = props;
  const urlParams = new URLSearchParams(additionalUrlParams);

  if (versionId) {
    urlParams.set("version_id", versionId);
  }
  if (environment) {
    urlParams.set("environment", environment);
  }
  return `/v5/${getApiResourceFromFileId(fileId)}/${fileId}?${urlParams.toString()}`;
};

/**
 * SWR hook mapped to our GET File endpoint.
 *
 * Supports "target" query params for retrieving a specific Version or deployment.
 */
export const useFile = (
  props: GetFileProps | null | undefined,
  swrOptions: SWRConfiguration<File> = {},
  additionalUrlParams: Record<string, string> | Array<[string, string]> | undefined = undefined,
) => {
  let key = null;
  if (props) {
    const url = getFileUrl(props, additionalUrlParams);
    key = [url, getAuthToken()];
  }
  const { data, error, mutate } = useSWR<File>(key, fileFetcher, swrOptions);
  return { file: data, error, loading: !data && !error, mutate };
};

export const useFileEnvironmentVariables = (
  fileId: string | null | undefined,
  swrOptions: SWRConfiguration<FileEnvironmentVariable[]> = {},
) => {
  const { data, error, mutate } = useSWR<FileEnvironmentVariable[]>(
    fileId ? [`/v5/${getApiResourceFromFileId(fileId)}/${fileId}/environment-variables`, getAuthToken()] : null,
    fileEnvironmentVariablesFetcher,
    swrOptions,
  );

  return { environmentVariables: data, error, loading: !data && !error, mutate };
};

const fileEnvironmentVariablesFetcher = async (
  ...args: Parameters<typeof ApiService.getWithToken>
): Promise<FileEnvironmentVariable[]> => {
  const response: FileEnvironmentVariable[] = await ApiService.getWithToken(...args);
  return response;
};

/**
 * Prefetch a file into the SWR cache to reduce loading time on navigation
 */
export const prefetchFile = (
  props: GetFileProps,
  additionalUrlParams: Record<string, string> | Array<[string, string]> | undefined = undefined,
) => {
  const url = getFileUrl(props, additionalUrlParams);
  return preload([url, getAuthToken()], fileFetcher);
};

// Environments that contain the deployed version of a File, if there is a deployed version.
export interface FileEnvironment extends Environment {
  file: File | null;
}

interface FileEnvironmentResponse extends EnvironmentResponse {
  file: FileResponse | null;
}

const fileEnvironmentsFetcher = async (
  ...args: Parameters<typeof ApiService.getWithToken>
): Promise<FileEnvironment[]> => {
  const response: FileEnvironmentResponse[] = await ApiService.getWithToken(...args);
  return response.map((v) => {
    return {
      ...v,
      ...parseEnvironmentResponse(v),
      file: v.file ? parseFileResponse(v.file) : v.file,
    } satisfies FileEnvironment;
  });
};

/**
 * SWR hook mapped to our GET File Environments endpoint.
 *
 * Lists what versions of the File are deployed to each environments.
 */
export const useFileEnvironments = (
  fileId: string | null | undefined,
  swrOptions: SWRConfiguration<FileEnvironment[]> = {},
) => {
  const { data, error, mutate } = useSWR<FileEnvironment[]>(
    fileId ? [`/v5/${getApiResourceFromFileId(fileId)}/${fileId}/environments`, getAuthToken()] : null,
    fileEnvironmentsFetcher,
    swrOptions,
  );

  return { fileEnvironments: data, error, loading: !data && !error, mutate };
};

// Used to prefill the SWR cache with a specific version.
export const mutateVersionInCache = (version: File) => {
  mutate([getFileUrl({ fileId: version.id, versionId: version.version_id }), getAuthToken()], version);
};

// Used to mutate a specific version (without the full version object)
export const mutateVersion = (props: GetFileProps) => {
  mutate([getFileUrl(props), getAuthToken()]);
};

// this tag is not visible to the user, but is used to sort by priority
export const PRIORITY_TAG = "__priority";

export const enum FilesSortBy {
  created_at = "created_at",
  updated_at = "updated_at",
  name = "name",
  tag = "tag",
}

export interface FileListProps {
  name?: string;
  fileTypes?: FileType[];
  user?: string;
  environment?: string; // Filter on user email addresses (will only show those with authorized access)
  sortBy?: FilesSortBy;
  order?: "asc" | "desc";
  pageSize?: number;
  template?: boolean;
}

// To be used when referring to a file in content.
export const capitalizedFileType = (fileType: FileType): string => {
  return _.capitalize(fileType);
};

/* Returns the API resource for a file type. Note that its plural. */
export const getApiResourceFromFileId = (fileId: string) => {
  let fileType: FileType;
  try {
    fileType = getFileTypeFromId(fileId);
  } catch (error) {
    console.error(`Error getting API resource from file ID: ${fileId}`, error);
    // Default to prompt as that was the legacy behaviour
    fileType = "prompt";
  }
  switch (fileType) {
    case "prompt":
      return "prompts";
    case "tool":
      return "tools";
    case "dataset":
      return "datasets";
    case "evaluator":
      return "evaluators";
    case "flow":
      return "flows";
    case "agent":
      return "agents";
  }
};

export const getSortedTags = (tags: string[]): string[] => {
  // don't show hidden priority tag to the user
  return tags.filter((tag) => tag !== PRIORITY_TAG).sort();
};

export const getFile = async (fileId: string): AxiosPromise<File> => {
  const response = await ApiService.get(`/v5/${getApiResourceFromFileId(fileId)}/${fileId}`);
  return { ...response, data: parseFileResponse(response.data) };
};

export const cloneFile = async (fileId: string, targetDirectoryId?: string): AxiosPromise<File> => {
  const response = await ApiService.post(
    `/v5/${getApiResourceFromFileId(fileId)}/${fileId}/clone`,
    targetDirectoryId ? { directory_id: targetDirectoryId } : undefined,
  );
  return { ...response, data: parseFileResponse(response.data) };
};

const listFilesFetcher = async <FR extends FileResponse>(...args: Parameters<typeof ApiService.getWithToken>) => {
  const response: FR[] = await ApiService.getWithToken(...args);
  return response.map(parseFileResponse);
};

export const deleteFile = (fileId: string): AxiosPromise<void> => {
  return ApiService.remove(`/v5/${getApiResourceFromFileId(fileId)}/${fileId}`);
};

export const deployToEnvironment = ({
  fileId,
  environmentId,
  versionId,
}: {
  fileId: string;
  environmentId: string;
  versionId: string;
}): AxiosPromise<File> => {
  return ApiService.post(
    `/v5/${getApiResourceFromFileId(fileId)}/${fileId}/environments/${environmentId}?version_id=${versionId}`,
  );
};

export const removeDeployment = ({
  fileId,
  environmentId,
}: {
  fileId: string;
  environmentId: string;
}): AxiosPromise<void> => {
  return ApiService.remove(`/v5/${getApiResourceFromFileId(fileId)}/${fileId}/environments/${environmentId}`);
};

export interface MonitoringEvaluatorVersionRequest {
  evaluator_version_id: string;
}

interface MonitoringEvaluatorEnvironmentRequest {
  evaluator_id: string;
  environment_id: string;
}

export type MonitoringEvaluatorRequest = MonitoringEvaluatorVersionRequest | MonitoringEvaluatorEnvironmentRequest;

export const getRequestFromMonitoringEvaluator = (
  monitoringEvaluator: MonitoringEvaluator,
): MonitoringEvaluatorRequest => {
  switch (monitoringEvaluator.version_reference.type) {
    case "environment":
      return {
        evaluator_id: monitoringEvaluator.version_reference.file.id,
        environment_id: monitoringEvaluator.version_reference.environment.id,
      };
    case "version":
      return { evaluator_version_id: monitoringEvaluator.version_reference.version.version_id };
  }
};

export type MonitoringType = "environment" | "version";

export type MonitoringEvaluatorState = "active" | "inactive";

interface DeploymentResponse {
  type: "environment";
  file: FileResponse;
  environment: EnvironmentResponse;
}

interface Deployment extends Omit<DeploymentResponse, "file" | "environment"> {
  file: File;
  environment: Environment;
}

interface VersionIdResponse {
  type: "version";
  version: FileResponse;
}

interface VersionId extends Omit<VersionIdResponse, "version"> {
  version: File;
}

export type MonitoringEvaluator = {
  state: MonitoringEvaluatorState;
  created_at: Dayjs;
  updated_at: Dayjs;
} & (
  | { version_reference: VersionId; version: Evaluator }
  | {
      version_reference: Deployment;
      version: Evaluator | null;
    }
);

export type MonitoringEvaluatorResponse = {
  state: MonitoringEvaluatorState;
  created_at: string;
  updated_at: string;
} & (
  | { version_reference: VersionIdResponse; version: EvaluatorResponse }
  | {
      version_reference: DeploymentResponse;
      version: EvaluatorResponse | null;
    }
);

export const parseMonitoringEvaluatorResponse = (response: MonitoringEvaluatorResponse): MonitoringEvaluator => {
  let versionReference: MonitoringEvaluator["version_reference"];
  switch (response.version_reference.type) {
    case "environment":
      versionReference = {
        ...response.version_reference,
        file: parseFileResponse(response.version_reference.file),
        environment: parseEnvironmentResponse(response.version_reference.environment),
      };
      break;
    case "version":
      versionReference = {
        ...response.version_reference,
        version: parseFileResponse(response.version_reference.version),
      };
      break;
  }
  return {
    ...parseTimestampsInResponse(response, ["created_at", "updated_at"]),
    state: response.state,
    version_reference: versionReference,
    version: response.version ? parseFileResponse(response.version) : null,
  } as MonitoringEvaluator;
};

interface MonitoringEvaluatorsRequest {
  activate?: MonitoringEvaluatorRequest[];
  deactivate?: MonitoringEvaluatorRequest[];
}

export const updateMonitoringEvaluators = (
  fileId: string,
  monitoringEvaluators: MonitoringEvaluatorsRequest,
): AxiosPromise<void> => {
  return ApiService.post(`/v5/${getApiResourceFromFileId(fileId)}/${fileId}/evaluators`, monitoringEvaluators);
};

/**
 * Helper function to check if the File has the specified Monitoring
 */
export const fileHasMonitoringEvaluator = (file: File, monitoringEvaluator: MonitoringEvaluatorRequest): boolean => {
  return (
    "evaluators" in file &&
    file.evaluators !== null &&
    file.evaluators.some((fileMonitoringEvaluator) => {
      if ("evaluator_id" in monitoringEvaluator) {
        return (
          fileMonitoringEvaluator.version_reference.type === "environment" &&
          fileMonitoringEvaluator.version_reference.file.id === monitoringEvaluator.evaluator_id &&
          fileMonitoringEvaluator.version_reference.environment.id === monitoringEvaluator.environment_id
        );
      } else {
        return (
          fileMonitoringEvaluator.version_reference.type === "version" &&
          fileMonitoringEvaluator.version_reference.version.version_id === monitoringEvaluator.evaluator_version_id
        );
      }
    })
  );
};

const getQueryParamsForRemoveMonitoringEvaluator = (monitoringEvaluator: MonitoringEvaluator): URLSearchParams => {
  const params = new URLSearchParams();

  Object.entries(getRequestFromMonitoringEvaluator(monitoringEvaluator)).forEach(([key, value]) => {
    params.append(key, value);
  });
  return params;
};

export const detachMonitoringEvaluators = (fileId: string, evaluator: MonitoringEvaluator): AxiosPromise<void> => {
  return ApiService.remove(
    `/v5/${getApiResourceFromFileId(fileId)}/${fileId}/evaluators` +
      `?${getQueryParamsForRemoveMonitoringEvaluator(evaluator)}`,
  );
};

/**
 * Global mutate to update all files and optionally a specific file.
 */
export const refetchFiles = (file: File): void => {
  // Global mutate to update all files in case you've changed the name of one of them etc.
  // See https://swr.vercel.app/docs/mutation#mutate-multiple-items
  // I think this could be a more used pattern to ensure multiple SWR caches are updated. but its
  // been implemented quickly, so open to restructuring this to a more general patter.
  // This is now being used, but the pattern and the key matching logic is pretty basic and could be improved.
  mutate(
    (key: Arguments) => {
      if (Array.isArray(key) && key[0].startsWith(`/v5/${getApiResourceFromFileId(file.id)}`)) {
        const pathWithoutQueryParams = key[0].split("?")[0];
        return pathWithoutQueryParams === `/v5/${getApiResourceFromFileId(file.id)}/${file.id}`;
      }
      return false;
    },
    file,
    { revalidate: true },
  );
};

interface UpdateFileRequest {
  path?: string;
  name?: string;
  directory_id?: string;
  description?: string;
  tags?: string[];
  readme?: string;
}

export const updateFile = async (fileId: string, update: UpdateFileRequest): AxiosPromise<File> => {
  const response = await ApiService.patch(`/v5/${getApiResourceFromFileId(fileId)}/${fileId}`, update);
  return { ...response, data: parseFileResponse(response.data) };
};

/**
 * Check if we should warn the user when renaming or moving a file or directory
 *
 * If the File or Directory are being used via API, we can't rule out that the user
 * is referencing the file by name - so we need to warn them about possible breaking changes.
 *
 * We don't want to always warn because when the file or directory is basically brand new
 * it would be very tedious to have to dismiss the warning every time.
 *
 * (Note that at the moment this is super basic), we should figure out what
 * good logic would be)
 */
// TODO: The type should just be File but the directories listing page doesn't return Files atm.
// TODO: switch to a more robust logic of when to show this warning (logs !== API usage with name)
export const shouldWarnOnPathChange = (
  item: FileOrDirectory,
):
  | {
      warn: boolean;
      warningMessage: string;
    }
  | false => {
  const itemType = item.type == "file" ? capitalizedFileType(item.item.type) : "Directory";
  const warningMessage = `Any API calls using paths (rather than IDs) will need to be updated.`;

  // We should try to include how many children a directory has, but until we have this we'll
  // always warn if the directory is not newly created.
  if (item.type == "directory") {
    if (item.item.created_at.isBefore(dayjs().subtract(1, "hour"))) {
      return { warn: true, warningMessage: warningMessage };
    } else {
      return false;
    }
  }

  // We use a DirectoryFile object in some places that's more limited than file.
  const directoryFile = item.item;

  switch (directoryFile.type) {
    // This should check for api access but we don't record that, so instead a quick check is if logs exists or not
    case "prompt":
    case "tool":
    case "evaluator":
    case "flow":
      return directoryFile.num_logs > 0
        ? { warn: true, warningMessage: `This ${itemType} has Logs. ` + warningMessage }
        : false;
    // Datasets are 'low risk' because its unlikely that it'll ever be used in a production environment.
    // So we won't warn on them, to avoid annoying users.
    case "dataset":
      return false;
    default:
      return { warn: true, warningMessage };
  }
};

/**
 * Convenience for renaming a file with toasts.
 */
export const renameFile = async ({
  file,
  name,
  onSuccess,
  onError,
}: {
  file: Pick<File, "id" | "type" | "name">;
  name: string;
  onSuccess?: (file: File) => void;
  onError?: (error: any) => void;
}) => {
  try {
    const updatedFile = (await updateFile(file.id, { name: name.trim() })).data;
    refetchFiles(updatedFile);
    hlToast({
      title: `${capitalizedFileType(file.type)} renamed`,
      description: `${capitalizedFileType(file.type)} '${file.name}' has been renamed to '${updatedFile.name}'`,
      variant: ToastVariant.Success,
    });
    onSuccess?.(updatedFile);
  } catch (error) {
    console.log(error);
    hlToastApiError({ error, titleFallback: `Failed to rename ${capitalizedFileType(file.type)}` });
    onError?.(error);
  }
};

export const getFilesParams = ({
  pageSize,
  pageNumber,
  name,
  fileTypes,
  user,
  environment,
  sortBy,
  order,
  template,
}: FileListProps & { pageNumber: number }): URLSearchParams => {
  const params = new URLSearchParams(
    (
      [
        ["size", pageSize],
        ["page", pageNumber],
        ["name", name],
        ["type", fileTypes?.join(",") || undefined],
        ["user", user],
        ["environment", environment],
        ["sort_by", sortBy],
        ["order", order],
        ["template", template],
      ] as [string, number | string | boolean][]
    )
      .filter(([k, v]) => k === "template" || _.isFinite(v) || !_.isEmpty(v))
      .map(([k, v]) => [k, `${v}`]),
  );
  return params;
};

export const getFiles = async (params: FileListProps & { pageNumber: number }): AxiosPromise<Page<File>> => {
  const response = await ApiService.get(`/v5/files?${getFilesParams(params).toString()}`);
  return { ...response, data: { ...response.data, records: response.data.records.map(parseFileResponse) } };
};

export const getFileEnvironmentVariables = async (fileId: string): AxiosPromise<FileEnvironmentVariable[]> => {
  const response = await ApiService.get(`/v5/files/${fileId}/environment-variables`);
  return response.data;
};

export const useFiles = (
  {
    pageSize = 20,
    name = "",
    fileTypes = [],
    user = "",
    environment = "",
    sortBy = FilesSortBy.created_at,
    order = "desc",
    template = false,
  }: FileListProps,
  swrOptions: SWRInfiniteConfiguration<Page<File>> = {},
) => {
  // Infinite loading version, returns the projects directly with tools to fetch next page.
  const {
    data,
    error,
    mutate,
    size: numPages,
    setSize: setNumPages,
    isValidating,
  } = useSWRInfinite<Page<File>>(
    (pageIndex, previousPageData) => [
      `/v5/files?${getFilesParams({
        pageSize,
        pageNumber: pageIndex + 1,
        name,
        fileTypes,
        user,
        environment,
        sortBy,
        order,
        template,
      })}`,
      getAuthToken(),
    ],
    filesFetcher,
    swrOptions,
  );

  const files = data ? ([] as File[]).concat(...data.map((page) => page.records)) : [];

  // Conveniences to understand the fetching state.
  const size = files.length;
  const total = data ? data[0].total : 0;
  const isLoadingInitialData = !data && !error;
  const isLoadingMore = isLoadingInitialData || (numPages > 0 && data && typeof data[numPages - 1] === "undefined");
  const isRefreshing = isValidating && data && data.length === numPages;
  const hasLoadedAll = size == total;

  return {
    files,
    error,
    mutate,
    numPages,
    setNumPages,
    size,
    total,
    isLoadingInitialData,
    isLoadingMore,
    isRefreshing,
    hasLoadedAll,
  };
};

export const useDefaultEvaluators = (swrOptions: SWRConfiguration<Evaluator[]> = {}) => {
  const { data, error, mutate } = useSWR<Evaluator[]>(
    [`/v5/evaluators/default`, getAuthToken()],
    listFilesFetcher<EvaluatorResponse>,
    {
      revalidateOnFocus: false,
      ...swrOptions,
    },
  );
  return { defaultEvaluators: data, error, loading: !data && !error, mutate };
};

export const commitVersion = async (fileId: string, versionId: string, commitMessage: string): AxiosPromise<File> => {
  const response = await ApiService.post(
    `/v5/${getApiResourceFromFileId(fileId)}/${fileId}/versions/${versionId}/commit`,
    {
      commit_message: commitMessage,
    },
  );
  return { ...response, data: parseFileResponse(response.data) };
};

export const deleteVersion = async (fileId: string, versionId: string): AxiosPromise<void> => {
  return ApiService.remove(`/v5/${getApiResourceFromFileId(fileId)}/${fileId}/versions/${versionId}`);
};

// Note: this is only applicable to Tools and Evaluators.
export const addEnvironmentVariable = async (
  fileId: string,
  environmentVariables: FileEnvironmentVariable[],
): AxiosPromise<FileEnvironmentVariable> => {
  return ApiService.post(
    `/v5/${getApiResourceFromFileId(fileId)}/${fileId}/environment-variables`,
    environmentVariables,
  );
};

export const deleteEnvironmentVariable = async (
  fileId: string,
  name: string,
): AxiosPromise<FileEnvironmentVariable[]> => {
  return ApiService.remove(`/v5/${getApiResourceFromFileId(fileId)}/${fileId}/environment-variables/${name}`);
};

import { BaseFileResponse, FileFromResponse, BaseIdentifiers } from "./file";
import { DashboardConfiguration } from "@/services/projects.service";
import {
  MonitoringEvaluator,
  MonitoringEvaluatorResponse,
  parseMonitoringEvaluatorResponse,
} from "@/services/files.service";
import { parseTimestamp, parseTimestampsInResponse } from "@/services/utils";
import { parseEnvironmentResponse } from "./environment";
import { EvaluatorAggregate } from "./version";
import { BaseLogResponse, LogFromResponse, LogResponse, EvaluatorLog, Log, parseLogResponse } from "./log";

export interface ToolFunction {
  name: string;
  description: string;
  // Should this be JsonSchema? Currently if I do that, we have invalid types on AI Judgment tool.
  parameters?: Record<string, any> | null;
  type?: string;
  strict?: boolean;
  [key: string]: any;
}

export interface ToolKernelRequest {
  function?: ToolFunction | null;
  source_code?: string | null;
  setup_values?: Record<string, any> | null;
  attributes?: Record<string, any> | null;
}

export interface ToolRequest extends ToolKernelRequest, ToolIdentifiers {
  tool_type: ToolType;
  commit_message?: string | null;
}

/**
 * Tool specific log input shared between ToolLogRequest and ToolCallRequest.
 */
export interface ToolLogInput {
  /**
   * Details of your Tool. A new Tool version will be created if the provided details are new.
   */
  tool: ToolKernelRequest | null;
}

/**
 * Tool-specific identifiers for locating tools in the Humanloop filesystem
 */
export interface ToolIdentifiers extends BaseIdentifiers {
  /**
   * Path of the Tool, including the name. This locates the Tool in the Humanloop filesystem and is used as as a unique identifier.
   * For example: `folder/name` or just `name`.
   */
  path?: string | null;

  /**
   * ID for an existing Tool.
   */
  id?: string | null;
}

/**
 * Request for creating a Tool call.
 */
export interface ToolCallRequest {
  path?: string | null;
  id?: string | null;
  directory_id?: string | null;
  tool: ToolKernelRequest;
  log_id?: string;
  inputs: Record<string, any>;
  source?: string;
  metadata?: Record<string, any> | null;
  save?: boolean;
  session_id?: string | null;
  parent_id?: string | null;
  source_datapoint_id?: string | null;
  batches?: string[] | null;
  user?: string | null;
  environment?: string | null;
  log_status?: "complete" | "incomplete" | null;
  start_time?: string | null;
  end_time?: string | null;
  trace_parent_id?: string | null;
}

/**
 * Response model for a Tool call.
 */
export interface ToolCallResponse extends BaseLogResponse, ToolLogInput {
  /**
   * ID of the log.
   */
  id: string;

  /**
   * ID of the Trace containing the Tool Call Log.
   */
  trace_id: string | null;

  /**
   * Tool used to generate the Log.
   */
  tool: ToolResponse;

  path: string;

  // Fields that might need explicit typing due to constraints or defaults
  inputs: Record<string, any>; // Default: {}
  trace_children: LogResponse[]; // Default: []
}

export interface UpdateToolRequest {
  path?: string | null;
  name?: string | null;
}

export interface ToolResponse extends Omit<ToolRequest, "id" | "path" | "directory_id">, BaseFileResponse<"tool"> {
  signature: string | null;

  version_logs_count: number;
  total_logs_count: number;

  inputs: { name: string }[];
  team_id: string;
  dashboard_configuration?: DashboardConfiguration;
  evaluators: MonitoringEvaluatorResponse[] | null;
  setup_schema: Record<string, any> | null;
  evaluator_aggregates: EvaluatorAggregate[] | null;
  schema: ToolFunction;
}

export interface Tool extends Omit<FileFromResponse<ToolResponse>, "evaluators"> {
  evaluators: MonitoringEvaluator[] | null;
}

export interface ToolCall extends Omit<LogFromResponse<ToolCallResponse>, "tool"> {
  tool: Tool;
  evaluator_logs: EvaluatorLog[];
  trace_children: Log[];
}

export const parseToolResponse = (response: ToolResponse): Tool => {
  return {
    ...parseTimestampsInResponse(response, ["created_at", "updated_at", "committed_at", "last_used_at"]),
    environments: response.environments.map(parseEnvironmentResponse),
    evaluators: response.evaluators
      ? response.evaluators.map((evaluator) => parseMonitoringEvaluatorResponse(evaluator))
      : null,
  };
};

export const parseToolCallResponse = (response: ToolCallResponse): ToolCall => {
  if (!response.created_at) {
    throw new Error(`ToolCallResponse is missing created_at: '${response.id}'`);
  }

  const parsedTimestamps = {
    created_at: parseTimestamp(response.created_at),
    updated_at: parseTimestamp(response.updated_at),
    start_time: response.start_time !== null ? parseTimestamp(response.start_time) : null,
    end_time: response.end_time !== null ? parseTimestamp(response.end_time) : null,
  };

  return {
    ...response,
    ...parsedTimestamps,
    tool: parseToolResponse(response.tool),
    evaluator_logs: response.evaluator_logs.map(parseLogResponse) as EvaluatorLog[],
    trace_children: response.trace_children.map(parseLogResponse),
  };
};

export interface ToolTemplateResponse extends ToolFunction {
  name: ToolType;
  signature: string | null;
  setup_schema: Record<string, any> | null;
}

export type ToolType = "pinecone_search" | "google" | "snippet" | "json_schema" | "get_api_call" | "python";

import { CheckCircle, Hash, Icon, IconProps, Wrench, Database, Cube, Stack, Folder } from "@phosphor-icons/react";
import { FlowIcon, ModelIcon } from "../Icons";
import { classNames } from "@/lib/utils";
import { FileType } from "@/types/app/file";
import { Gavel } from "@phosphor-icons/react";
import { FolderIcon } from "@heroicons/react/outline";
import { Robot } from "@phosphor-icons/react/dist/ssr";

export type IdEntity = "log" | "batch" | "datapoint"; // Will be expanded to include "session" in the future
export type EntityIconType =
  | FileType
  | IdEntity
  | "hash"
  | "committed-deployed"
  | "committed-not-deployed"
  | "loading"
  | "directory";
export type EntityIconSize = 12 | 14 | 16 | 20 | 24 | 32;

interface Props {
  type: EntityIconType;
  size: EntityIconSize;
  iconBadgeClassName?: string;
}

/**
 * https://www.figma.com/file/Ya7mCOIZJp1UdBCH3VxnEL/Components-v2?type=design&node-id=2021-6014&mode=design&t=c1YrAB68h7OX5HYx-0
 */
export const EntityIcon = ({ type, size, iconBadgeClassName }: Props) => {
  const { icon, badgeColor, wrapInBadge } = ENTITY_STYLES[type];
  const { badge: badgeSize, icon: iconSize } = SIZE_CLASSNAMES[size];

  if (wrapInBadge) {
    return (
      <div
        className={classNames("flex shrink-0 items-center justify-center", badgeSize, badgeColor, iconBadgeClassName)}
      >
        <icon.Icon className={classNames("shrink-0", iconSize)} {...("iconProps" in icon ? icon.iconProps : {})} />
      </div>
    );
  } else {
    return (
      <icon.Icon
        {...("iconProps" in icon ? icon.iconProps : {})}
        className={classNames(
          "flex shrink-0 items-center justify-center",
          badgeSize,
          badgeColor,
          "iconProps" in icon && icon.iconProps?.className,
        )}
      />
    );
  }
};

interface EntityStyling {
  icon: { Icon: React.ComponentType<React.SVGProps<SVGSVGElement>> } | { Icon: Icon; iconProps?: IconProps };
  badgeColor: string;
  wrapInBadge: boolean; // Whether we need to wrap the icon in a badge. If false, the icon will be rendered with the badge sizes
}

const ENTITY_STYLES: Record<EntityIconType, EntityStyling> = {
  "prompt": {
    icon: { Icon: ModelIcon },
    badgeColor: "bg-gray-700 text-icon-base-5",
    wrapInBadge: true,
  },
  "agent": {
    icon: { Icon: Robot, iconProps: { weight: "fill" } },
    badgeColor: "bg-gray-700 text-icon-base-5",
    wrapInBadge: true,
  },
  "tool": {
    icon: { Icon: Wrench, iconProps: { weight: "fill" } },
    badgeColor: "bg-violet-500 text-icon-base-5",
    wrapInBadge: true,
  },
  "dataset": {
    icon: { Icon: Database, iconProps: { weight: "fill" } },
    badgeColor: "bg-blue-500 text-icon-base-5",
    wrapInBadge: true,
  },
  "evaluator": {
    icon: { Icon: Gavel, iconProps: { weight: "fill" } },
    badgeColor: "bg-orange-500 text-icon-base-5",
    wrapInBadge: true,
  },
  "flow": {
    icon: { Icon: FlowIcon, iconProps: { weight: "fill" } },
    badgeColor: "bg-cyan-500 text-icon-base-5",
    wrapInBadge: true,
  },
  "committed-deployed": {
    icon: { Icon: CheckCircle, iconProps: { weight: "fill" } },
    badgeColor: "text-icon-success-2",
    wrapInBadge: false,
  },
  "committed-not-deployed": {
    icon: { Icon: CheckCircle, iconProps: { weight: "bold" } },
    badgeColor: "text-icon-base-1",
    wrapInBadge: false,
  },
  "hash": {
    icon: { Icon: Hash, iconProps: { weight: "bold", opacity: 0.6 } },
    badgeColor: "text-icon-base-1",
    wrapInBadge: false,
  },
  "log": {
    icon: { Icon: Cube, iconProps: { weight: "fill" } },
    badgeColor: "text-icon-base-2",
    wrapInBadge: false,
  },
  "datapoint": {
    icon: { Icon: Cube, iconProps: { weight: "fill" } },
    badgeColor: "text-icon-base-2",
    wrapInBadge: false,
  },
  "batch": {
    icon: { Icon: Stack, iconProps: { weight: "fill" } },
    badgeColor: "text-icon-base-2",
    wrapInBadge: false,
  },
  "directory": {
    icon: { Icon: FolderIcon, iconProps: { className: "shrink-0" } },
    badgeColor: "text-icon-base-2",
    wrapInBadge: false,
  },
  "loading": {
    icon: { Icon: ModelIcon },
    badgeColor: "bg-gray-300 text-icon-base-5",
    wrapInBadge: true,
  },
};

// `icon` is the size of the inner icon if the icon is to be wrapped in a badge
const SIZE_CLASSNAMES: Record<EntityIconSize, { badge: string; icon: string }> = {
  12: { badge: "w-12 h-12 rounded-[2.571px]", icon: "h-[9px] w-[9px]" },
  14: { badge: "w-14 h-14 rounded-[3px]", icon: "h-[10.5px] w-[10.5px]" },
  16: { badge: "w-16 h-16 rounded-ms", icon: "h-12 w-12" },
  20: { badge: "w-20 h-20 rounded-ms", icon: "h-[15px] w-[15px]" },
  24: { badge: "w-24 h-24 rounded-ms", icon: "h-[18px] w-[18px]" },
  32: { badge: "w-32 h-32 rounded-ms", icon: "h-[24px] w-[24px]" },
};

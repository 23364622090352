import { ChatMessage, ChatMessageData, ModelEndpoint, ModelProvider } from "@/services/playground.service";
import { BaseFileResponse, FileFromResponse } from "./file";
import { ToolFunction } from "./tool";
import { parseTimestampsInResponse } from "@/services/utils";
import { DashboardConfiguration } from "@/services/projects.service";
import {
  MonitoringEvaluator,
  MonitoringEvaluatorResponse,
  parseMonitoringEvaluatorResponse,
} from "@/services/files.service";
import { parseEnvironmentResponse } from "./environment";
import { EvaluatorAggregate } from "./version";
import { FileRequestIdentifiers, LogRequestTimeStamps, LogResponse } from "./log";
import _ from "lodash";
import { PromptCallResponseToolChoice, ProviderApiKeys, ToolChoice } from "humanloop/api";

type PromptTemplate = string;
type ChatTemplate = ChatMessageData[];

export const JSON_OBJECT_RESPONSE_FORMAT = { type: "json_object" } as const;
export const JSON_SCHEMA_RESPONSE_FORMAT = { type: "json_schema", json_schema: {} } as const;
export type ResponseFormat = typeof JSON_OBJECT_RESPONSE_FORMAT | typeof JSON_SCHEMA_RESPONSE_FORMAT;
export type TemplateLanguage = "default" | "jinja";

export interface PromptKernelRequest {
  model: string;
  endpoint: ModelEndpoint;
  template: PromptTemplate | ChatTemplate | null;
  provider: ModelProvider;
  template_language?: TemplateLanguage | null;

  max_tokens?: number | null;
  temperature?: number | null;
  top_p?: number | null;

  stop?: string[] | string | null;

  frequency_penalty?: number | null;
  presence_penalty?: number | null;
  other?: Record<string, any> | null;
  seed?: number | null;
  response_format?: ResponseFormat | null;
  reasoning_effort?: "high" | "medium" | "low" | null;
  tools?: ToolFunction[] | null;
  linked_tools?: string[] | null;
  attributes?: Record<string, any> | null;
}

export interface PromptRequest extends PromptKernelRequest, FileRequestIdentifiers, LogRequestTimeStamps {
  commit_message?: string | null;
}

export interface UpdatePromptRequest {
  path?: string | null;
  name?: string | null;
}

interface LinkedToolResponse extends ToolFunction {
  id: string;
  version_id: string;
}

export interface PromptResponse
  extends Omit<PromptRequest, "id" | "path" | "directory_id" | "linked_tools">,
    BaseFileResponse<"prompt"> {
  linked_tools: LinkedToolResponse[];
  schema: ToolFunction;
  version_logs_count: number;
  total_logs_count: number;
  evaluators: MonitoringEvaluatorResponse[] | null;
  inputs: { name: string }[];
  team_id: string;
  dashboard_configuration?: DashboardConfiguration;
  evaluator_aggregates: EvaluatorAggregate[] | null;
}

export interface PromptCallRequest {
  id: string;
  path?: string;
  prompt?: PromptKernelRequest;
  messages?: ChatMessage[];
  tool_choice?: "none" | "auto" | "required" | ToolChoice;
  inputs?: Record<string, unknown>;
  source?: string;
  metadata?: Record<string, unknown>;
  start_time?: string;
  end_time?: string;
  log_status?: "pending" | "complete" | "failed";
  source_datapoint_id?: string;
  trace_parent_id?: string;
  user?: string;
  environment?: string;
  save?: boolean;
  log_id?: string;
  provider_api_keys?: ProviderApiKeys;
  num_samples?: number;
  stream?: boolean;
  return_inputs?: boolean;
  logprobs?: number;
  suffix?: string;
}

export interface PromptCallResponse {
  /** When the logged event started. */
  start_time?: Date;
  /** When the logged event ended. */
  end_time?: Date;
  /** The messages passed to the to provider chat endpoint. */
  messages?: ChatMessage[];
  /**
   * Controls how the model uses tools. The following options are supported:
   * - `'none'` means the model will not call any tool and instead generates a message; this is the default when no tools are provided as part of the Prompt.
   * - `'auto'` means the model can decide to call one or more of the provided tools; this is the default when tools are provided as part of the Prompt.
   * - `'required'` means the model can decide to call one or more of the provided tools.
   * - `{'type': 'function', 'function': {name': <TOOL_NAME>}}` forces the model to use the named function.
   */
  tool_choice?: "none" | "auto" | "required" | ToolChoice;
  /** Prompt used to generate the Log. */
  prompt: PromptResponse;
  /** The inputs passed to the prompt template. */
  inputs?: Record<string, unknown>;
  /** Identifies where the model was called from. */
  source?: string;
  /** Any additional metadata to record. */
  metadata?: Record<string, unknown>;
  /** Unique identifier for the Datapoint that this Log is derived from. This can be used by Humanloop to associate Logs to Evaluations. If provided, Humanloop will automatically associate this Log to Evaluations that require a Log for this Datapoint-Version pair. */
  source_datapoint_id?: string;
  /** The ID of the parent Log to nest this Log under in a Trace. */
  trace_parent_id?: string;
  /** End-user ID related to the Log. */
  user?: string;
  /** The name of the Environment the Log is associated to. */
  environment?: string;
  /** Whether the request/response payloads will be stored on Humanloop. */
  save?: boolean;

  // TODO: why do we have two IDs for a log??
  /** This will identify a Log. If you don't provide a Log ID, Humanloop will generate one for you. */
  log_id?: string;
  /** ID of the Log. */
  id: string;

  /** ID of the Trace containing the Prompt Call Log. */
  trace_id?: string;
  /** The logs generated by the Prompt call. */
  logs: LogResponse[];
}

/**
 * Response model for calling Prompt in streaming mode.
 */
export interface PromptCallStreamResponse {
  /** Generated output from your model for the provided inputs. Can be `None` if logging an error, or if creating a parent Log with the intention to populate it later. */
  output?: string;
  /** User defined timestamp for when the log was created. */
  created_at?: Date;
  /** Error message if the log is an error. */
  error?: string;
  /** Duration of the logged event in seconds. */
  provider_latency?: number;
  /** Captured log and debug statements. */
  stdout?: string;
  /** The message returned by the provider. */
  output_message?: ChatMessage;
  /** Number of tokens in the prompt used to generate the output. */
  prompt_tokens?: number;
  /** Number of tokens in the output generated by the model. */
  output_tokens?: number;
  /** Cost in dollars associated to the tokens in the prompt. */
  prompt_cost?: number;
  /** Cost in dollars associated to the tokens in the output. */
  output_cost?: number;
  /** Reason the generation finished. */
  finish_reason?: string;
  /** The index of the sample in the batch. */
  index: number;
  /** ID of the log. */
  id: string;
  /** ID of the Prompt the log belongs to. */
  prompt_id: string;
  /** ID of the specific version of the Prompt. */
  version_id: string;
}

export interface Prompt extends Omit<FileFromResponse<PromptResponse>, "evaluators"> {
  evaluators: MonitoringEvaluator[] | null;
}

export const parsePromptResponse = (response: PromptResponse): Prompt => {
  return {
    ...parseTimestampsInResponse(response, ["created_at", "updated_at", "committed_at", "last_used_at"]),
    environments: response.environments.map(parseEnvironmentResponse),
    evaluators: response.evaluators
      ? response.evaluators.map((evaluator) => parseMonitoringEvaluatorResponse(evaluator))
      : null,
  };
};

/** Helper to check equality of Prompt kernels.
 *
 * Our backend can add in null attributes that do not actually change the Prompt.
 * E.g. `prompt.template[0].name` would be returned as `null` in our backend if unset.
 */
export const promptIsEqual = (a: PromptKernelRequest, b: PromptKernelRequest): boolean => {
  const cleanPrompt = (prompt: PromptKernelRequest) => {
    let cleanedPrompt = _.omitBy(prompt, _.isEmpty);
    if (cleanedPrompt.template && Array.isArray(cleanedPrompt.template)) {
      cleanedPrompt.template = cleanedPrompt.template.map((chatMessage) => _.omitBy(chatMessage, _.isEmpty));
    }
    return cleanedPrompt;
  };
  const cleanedA = cleanPrompt(a);
  const cleanedB = cleanPrompt(b);
  console.log({ cleanedA, cleanedB, isEqual: _.isEqual(cleanedA, cleanedB) });
  return _.isEqual(cleanedA, cleanedB);
};

import { parseTimestampsInResponse } from "@/services/utils";
import { MonitoringEvaluator, parseMonitoringEvaluatorResponse } from "@/services/files.service";
import { FileFromResponse, FileResponse } from "./file";
import { FileRequestIdentifiers } from "./log";
import { PromptKernelRequest, PromptResponse } from "./prompt";
import { parseEnvironmentResponse } from "./environment";
import { ChatMessage } from "@/services/playground.service";
import { LogResponse, ProviderApiKeys, ToolChoice, ToolFunction } from "humanloop/api";

export interface LinkedFileRequest {
  file_id: string;
  environment_id?: string | null;
  version_id?: string | null;
}
export type AgentToolRequest =
  | { type: "file"; link: LinkedFileRequest }
  | { type: "inline"; json_schema: ToolFunction };
type AgentToolResponse =
  | { type: "file"; link: LinkedFileRequest; file: FileResponse }
  | { type: "inline"; json_schema: ToolFunction };

export interface AgentKernelRequest extends Omit<PromptKernelRequest, "tools" | "linked_tools"> {
  tools?: AgentToolRequest[];
  max_iterations?: number | null;
}

export interface AgentRequest extends AgentKernelRequest, FileRequestIdentifiers {
  commit_message: string | null;
  version_name?: string | null;
  version_description?: string | null;
  description?: string | null;
  tags?: string[];
  readme?: string | null;
}

export interface AgentResponse extends Omit<PromptResponse, "type" | "tools" | "linked_tools"> {
  type: "agent";
  tools: AgentToolResponse[];
}

export interface AgentCallRequest {
  id: string;
  path?: string;
  agent?: AgentKernelRequest;
  messages?: ChatMessage[];
  tool_choice?: "none" | "auto" | "required" | ToolChoice;
  inputs?: Record<string, unknown>;
  source?: string;
  metadata?: Record<string, unknown>;
  start_time?: string;
  end_time?: string;
  log_status?: "pending" | "complete" | "failed";
  source_datapoint_id?: string;
  trace_parent_id?: string;
  user?: string;
  environment?: string;
  save?: boolean;
  log_id?: string;
  provider_api_keys?: ProviderApiKeys;
  num_samples?: number;
  stream?: boolean;
  return_inputs?: boolean;
  logprobs?: number;
  suffix?: string;
}

export interface AgentCallResponse {
  /** When the logged event started. */
  start_time?: Date;
  /** When the logged event ended. */
  end_time?: Date;
  /** The messages passed to the to provider chat endpoint. */
  messages?: ChatMessage[];
  /**
   * Controls how the model uses tools. The following options are supported:
   * - `'none'` means the model will not call any tool and instead generates a message; this is the default when no tools are provided as part of the Agent.
   * - `'auto'` means the model can decide to call one or more of the provided tools; this is the default when tools are provided as part of the Agent.
   * - `'required'` means the model can decide to call one or more of the provided tools.
   * - `{'type': 'function', 'function': {name': <TOOL_NAME>}}` forces the model to use the named function.
   */
  tool_choice?: "none" | "auto" | "required" | ToolChoice;
  /** Agent used to generate the Log. */
  prompt: AgentResponse;
  /** The inputs passed to the prompt template. */
  inputs?: Record<string, unknown>;
  /** Identifies where the model was called from. */
  source?: string;
  /** Any additional metadata to record. */
  metadata?: Record<string, unknown>;
  /** Unique identifier for the Datapoint that this Log is derived from. This can be used by Humanloop to associate Logs to Evaluations. If provided, Humanloop will automatically associate this Log to Evaluations that require a Log for this Datapoint-Version pair. */
  source_datapoint_id?: string;
  /** The ID of the parent Log to nest this Log under in a Trace. */
  trace_parent_id?: string;
  /** End-user ID related to the Log. */
  user?: string;
  /** The name of the Environment the Log is associated to. */
  environment?: string;
  /** Whether the request/response payloads will be stored on Humanloop. */
  save?: boolean;
  /** This will identify a Log. If you don't provide a Log ID, Humanloop will generate one for you. */
  log_id?: string;
  /** ID of the log. */
  id: string;
  /** ID of the Trace containing the Agent Call Log. */
  trace_id?: string;
  /** The logs generated by the Agent call. */
  logs: LogResponse[];
}

/**
 * Response model for calling Agent in streaming mode.
 */
export interface AgentCallStreamResponse {
  /** Generated output from your model for the provided inputs. Can be `None` if logging an error, or if creating a parent Log with the intention to populate it later. */
  output?: string;
  /** User defined timestamp for when the log was created. */
  created_at?: Date;
  /** Error message if the log is an error. */
  error?: string;
  /** Duration of the logged event in seconds. */
  provider_latency?: number;
  /** Captured log and debug statements. */
  stdout?: string;
  /** The message returned by the provider. */
  output_message?: ChatMessage;
  /** Number of tokens in the prompt used to generate the output. */
  prompt_tokens?: number;
  /** Number of tokens in the output generated by the model. */
  output_tokens?: number;
  /** Cost in dollars associated to the tokens in the prompt. */
  prompt_cost?: number;
  /** Cost in dollars associated to the tokens in the output. */
  output_cost?: number;
  /** Reason the generation finished. */
  finish_reason?: string;
  /** The index of the sample in the batch. */
  index: number;
  /** ID of the log. */
  id: string;
  /** ID of the Agent the log belongs to. */
  prompt_id: string;
  /** ID of the specific version of the Agent. */
  version_id: string;
}

export interface Agent extends Omit<FileFromResponse<AgentResponse>, "evaluators"> {
  evaluators: MonitoringEvaluator[] | null;
  environments: any[];
}

export const parseAgentResponse = (response: AgentResponse): Agent => {
  return {
    ...response,
    ...parseTimestampsInResponse(response, ["created_at", "updated_at", "committed_at", "last_used_at"]),
    environments: response.environments.map(parseEnvironmentResponse),
    evaluators: response.evaluators
      ? response.evaluators.map((evaluator) => parseMonitoringEvaluatorResponse(evaluator))
      : null,
  };
};

import dayjs from "dayjs";
import { Dataset, DatasetResponse, parseDatasetResponse } from "./dataset";
import { Evaluator, EvaluatorResponse, getIsLLMEvaluator, parseEvaluatorResponse } from "./evaluator";
import { Prompt, PromptResponse, parsePromptResponse } from "./prompt";
import { Tool, ToolResponse, parseToolResponse } from "./tool";
import { VersionStatus } from "./version";
import { Environment, EnvironmentResponse, parseEnvironmentResponse } from "./environment";
import { User } from "./user";
import { parseTimestamp } from "@/services/utils";
import { Flow, FlowResponse, parseFlowResponse } from "./flow";
import { Agent, AgentResponse, parseAgentResponse } from "./agent";

export interface BaseFileResponse<FT extends FileType> {
  type: FT;
  path: string;
  id: string;
  name: string;
  description: string;
  readme: string;
  tags: string[];

  version_id: string;
  directory_id: string;
  environments: EnvironmentResponse[];

  created_at: string;
  updated_at: string;
  created_by: User | null;

  committed_at: string | null;
  committed_by: User | null;

  status: VersionStatus;
  last_used_at: string;
}

export const FILE_TYPES = ["prompt", "tool", "dataset", "evaluator", "flow", "agent"] as const;
export const DIRECTORY_TYPE = "directory";
export const SOURCE_TYPE = ["humanloop", "huggingface"];
export const FILE_TYPES_AND_DIRECTORY = [...FILE_TYPES, DIRECTORY_TYPE] as const;

export type FileType = (typeof FILE_TYPES)[number];
export type FileTypeOrDirectory = FileType | "directory";

// Helper to convert a BaseFileResponse to a File.
export type FileFromResponse<F extends BaseFileResponse<any>> = Omit<
  F,
  "created_at" | "updated_at" | "last_used_at" | "environments" | "committed_at"
> & {
  created_at: dayjs.Dayjs;
  updated_at: dayjs.Dayjs;
  committed_at: dayjs.Dayjs | null;
  last_used_at: dayjs.Dayjs;
  environments: Environment[];
};

export const parseBaseFileResponse = <R extends BaseFileResponse<any>>(response: R): FileFromResponse<R> => {
  return {
    ...response,
    created_at: parseTimestamp(response.created_at),
    updated_at: parseTimestamp(response.updated_at),
    committed_at: response.committed_at ? parseTimestamp(response.committed_at) : null,
    last_used_at: parseTimestamp(response.last_used_at),
    environments: response.environments.map(parseEnvironmentResponse),
  };
};

export type FileResponse =
  | PromptResponse
  | ToolResponse
  | DatasetResponse
  | EvaluatorResponse
  | FlowResponse
  | AgentResponse;

export type File = Prompt | Tool | Dataset | Evaluator | Flow | Agent;

type ExtractType<T> = T extends BaseFileResponse<infer U> ? U : never;
// Overload to parse the response based on the file Type
export function parseFileResponse<T extends FileResponse>(response: T): File & { type: ExtractType<T> };
export function parseFileResponse(response: FileResponse): File {
  switch (response.type) {
    case "tool":
      return parseToolResponse(response);
    case "prompt":
      return parsePromptResponse(response);
    case "dataset":
      return parseDatasetResponse(response);
    case "evaluator":
      return parseEvaluatorResponse(response);
    case "flow":
      return parseFlowResponse(response);
    case "agent":
      // TODO
      return parseAgentResponse(response);
    default:
      const _exhaustiveCheck: never = response;
      throw new Error(`Unhandled response type: ${_exhaustiveCheck}`);
  }
}

/**
 * Whether the File has Logs that can be Evaluated.
 *
 * Datasets don't have Logs.
 * We don't allow Code/Human Evaluators to be evaluated.
 */
export const getFileSupportsEvaluators = (file: File): boolean => {
  switch (file.type) {
    case "prompt":
    case "tool":
    case "agent":
      return true;
    case "dataset":
      return false;
    case "evaluator":
      // Only allow evaluators for LLM evaluators
      if (getIsLLMEvaluator(file)) {
        return true;
      } else {
        return false;
      }
    case "flow":
      return true;

    default:
      const _exhaustiveCheck: never = file;
      throw new Error(`Unhandled file type: ${_exhaustiveCheck}`);
  }
};

/**
 * Base identifiers for locating files in the Humanloop filesystem
 */
export interface BaseIdentifiers {
  /**
   * Path of the File, including the name. This locates the File in the Humanloop filesystem and is used as a unique identifier.
   * For example: `folder/name` or just `name`.
   */
  path?: string | null;

  /**
   * ID for an existing File.
   */
  id?: string | null;

  /**
   * ID of the Directory to create the File in.
   * Used by our frontend only to create an unnamed File in a specific Directory,
   * which would otherwise not be possible through `path`.
   */
  directory_id?: string | null;
}
